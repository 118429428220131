import React, {useState, useEffect, useContext, useRef} from 'react';
import DOMPurify from 'dompurify';

import { SearchConfigContext } from './context/searchConfigContext.js';

import EntityThumbnail from './components/entityThumbnail';
import { getImageAltText, getFallbackImageUrl, FALLBACK_IMAGE_SIZES, sizeResettingErrorHandler, truncateWordBoundary, eventNormalize } from './util.js';

import { QuickViewer } from './components/quickView.js';
import { ItemOverlay } from './components/itemOverlay.js';

import '../css/search.scoped.scss';

const RESULTS_MODE = {
  GRID: 'GRID',
  LIST: 'LIST'
}

const LAZY_LOAD_THRESHOLD = 5

const ResultsPageControl = (props) => {

  let firstPageAvail = props.fromIndex > 0
  let prevPageAvail = props.fromIndex > 0

  let nextPageAvail = ( props.fromIndex + props.pageSize ) < props.count
  let lastPageAvail = ( props.fromIndex + props.pageSize ) < props.count

  let currentPage = props.count > 0 ? Number(props.fromIndex / props.pageSize) + 1 : 0;
  let totalPage = Math.ceil(props.count / props.pageSize);

  function nextPageHandler(event) {
    if (nextPageAvail) {
      let idx = props.fromIndex + props.pageSize
      props.changePage(idx)
    }
  }

  function prevPageHandler(event) {
    if (prevPageAvail) {
      let idx = ( props.fromIndex - props.pageSize > 0 ) ? props.fromIndex - props.pageSize : 0
      props.changePage(idx)
    }

  }

  function lastPageHandler(event) {
    if (lastPageAvail) {
      let lastPage = Math.floor( props.count / props.pageSize )
      let lastPageIndex = lastPage * props.pageSize
      props.changePage(lastPageIndex)
    }
  }

  function firstPageHandler(event) {
    if (firstPageAvail) {
      props.changePage(0)
    }
  }

  function sortbyHandler(event) {
    props.changeSort(event.target.value);
  }

  function pagination(c, m) {
      var current = c,
          last = m,
          delta = 2,
          left = current - delta,
          right = current + delta + 1,
          range = [],
          rangeWithDots = [],
          l;

      for (let i = 1; i <= last; i++) {
          if (i == 1 || i == last || i >= left && i < right) {
              range.push(i);
          }
      }

      for (let i of range) {
          if (l) {
              if (i - l === 2) {
                  rangeWithDots.push(l + 1);
              } else if (i - l !== 1) {
                  rangeWithDots.push('...');
              }
          }
          rangeWithDots.push(i);
          l = i;
      }

      return rangeWithDots;
  }

  const sortbyOptions = {
    'Artwork': [{
          'value': 'nameaz',
          'label': 'Name (A-Z)'
        }, {
          'value': 'nameza',
          'label': 'Name (Z-A)'
        }, {
          'value': 'creatornameaz',
          'label': 'Artist name'
        }],
    'Person': [{
          'value': 'nameaz',
          'label': 'Name (A-Z)'
        }, {
          'value': 'nameza',
          'label': 'Name (Z-A)'
        }, {
          'value': 'lifedates',
          'label': 'Life dates'
        }],
    'default': [{
          'value': 'nameaz',
          'label': 'Name (A-Z)'
        }, {
          'value': 'nameza',
          'label': 'Name (Z-A)'
        }]
    };

  const mySortbyOptions = (['Artwork', 'Person'].includes(props.type))
    ? sortbyOptions[props.type]
    : sortbyOptions['default'];

  return (
    <div>
      {/* <hr className="section-archive-landing-hr" /> */}
      <div className="columns is-vcentered">
        <span className="column is-5 section-art-landing-count">{`${props.labelConfig.resultsShowing} ${ props.count > 0 ? props.fromIndex + 1 : 0 }-${Math.min((props.fromIndex + props.pageSize), props.count)} ${props.labelConfig.resultsOf} ${props.count} ${props.labelConfig.resultsResults} (${props.labelConfig.resultsPage} ${currentPage }/${totalPage})`}</span>
        <div className="column is-7 pagination-section">
          <span className="is-size-6" style={{userSelect:'none', lineHeight:1.5}}>&nbsp;
          <a role="button" tabIndex='0' className="section-archive-landing-nav-first mr-1" onClick={firstPageHandler} >&lt;&lt;</a>&nbsp;<a role="button" tabIndex='0' className="section-archive-landing-nav-previous mr-1" onClick={prevPageHandler} >&lt;</a>&nbsp;
          <span className="section-art-landing-nav-pages">{
          <React.Fragment>{pagination(currentPage, totalPage).map( (pg, idx) => pg !== "..." ? <a href="#" className={`m-1 ${currentPage === pg ? 'is-active' : '' }`} key={idx} onClick={ (event) => { props.changePage( (pg - 1) * props.pageSize) ; event.preventDefault(); }} >{pg}</a> : <span key={idx}>{pg}</span> )}</React.Fragment> }
          </span>&nbsp;
          <a role="button" tabIndex='0' className="section-archive-landing-nav-next mr-1" onClick={nextPageHandler} >&gt;</a>&nbsp;<a role="button" tabIndex='0' className="section-archive-landing-nav-last ml-1" onClick={lastPageHandler}>&gt;&gt;</a></span>
          <div className={ props.showViewMode ? '' : 'is-hidden' }><button aria-label="Click to change to grid view" title="Grid view" className={`button icon-button ${props.mode === RESULTS_MODE.GRID ? 'is-active' : '' }`} onClick={props.resultsModeGrid}> <span className="icon icon-grid-view"></span></button><button title="List view" aria-label="Click to change to list view" className={`button icon-button ${props.mode === RESULTS_MODE.LIST ? 'is-active' : '' }`} onClick={props.resultsModeList}><span className="icon icon-list-view"></span></button></div>
        </div>
      </div>
      <div className="is-clearfix">&nbsp;</div>
    </div>
  )
}

const ResultsView = (props) => {
  switch (props.mode) {
    case RESULTS_MODE.LIST:
      return <ResultsListView results={props.results} searchStr={props.searchStr} />
    case RESULTS_MODE.GRID:
      return <ResultsGridView labels={props.labels} count={props.count} results={props.results} searchStr={props.searchStr} />
  }
}

const ResultPill = (props) => {
  switch (props.classification.toLowerCase()) {
    case "male":
      return <div className="pill">M</div>
      break;
    case "female":
      return <div className="pill pill--female">F</div>
      break;
    case "unknown":
      return <div className="pill pull--unknown">U</div>
      break;
    default:
      return <div className="pill">{props.classification}</div>
      break;
  }
}

const ActivityDatesLocationsView = (props) => {
  return <td>
      {props.dataSource.map((activity,idx) => {
          return <React.Fragment key={idx}>
                  <span className="landing-page__table-locations">
                    {activity.location.label}
                  </span>
                  <h5 className="landing-page__table-year">{activity.date_label}</h5>
                  <br />
                </React.Fragment>
        })
      }
    </td>
}

const getResultYearRangeString = (y0, y1) => {
  let s0 = '';
  let s1 = '';
  if (y0) {
    const d = y0['date_label'];
    if (d) {
      const n = parseInt(d.substr(0, 4));
      if (n >= 1000 && n < 10000) { // i.e. does the string begin w. 4 digit number?
        s0 = n;
      }
    }
  }
  if (y1) {
    const d = y1['date_label'];
    if (d) {
      const n = parseInt(d.substr(0, 4));
      if (n >= 1000 && n < 10000) { // i.e. does the string begin w. 4 digit number?
        s1 = n;
      }
    }
  }
  return (s0 + (s0 !== '' && s1 !== '' ? '-' : '') + s1);
}

const ResultListItem = (props) => {

    const config = useContext(SearchConfigContext);
    const labels = config.searchConfig.labels.reduce((acc,cur) => { acc[cur.name] = cur.label ; return acc }, {} )

    const mapping = config.mapping
    let itemLink = mapping.link(props.result)

    if ( props.result._id.search('^http(s?)://') > -1 ) {
      const ident = new URL(props.result._id);
      itemLink = `${ident.pathname}/`;
    }

    const displayType = mapping.displayType(props.result);
    const descriptionShort = (mapping.description(props.result) && truncateWordBoundary(mapping.description(props.result), 120)) ||
        'No description available';

    const label = DOMPurify.sanitize(mapping.label(props.result), {ALLOWED_TAGS: []});
    const makers = DOMPurify.sanitize(mapping.makers(props.result), {ALLOWED_TAGS: ['br']});

    const itemClickHandler = (event) => {

      if ( mapping.isReference !== undefined && mapping.isReference(props.result) ) {
        event.stopPropagation();
        event.preventDefault();
      }

    }

    const imageUrl = mapping.imageUrl(props.result) ?? getFallbackImageUrl(props.result,FALLBACK_IMAGE_SIZES.SMALL)
    const imageAltText = mapping.imageUrl(props.result) ? "" : getImageAltText(props.result)

    return <div className="section-search-list-row">
    <a href={itemLink} onClick={itemClickHandler}>
        <div className="columns is-flex-mobile is-vcentered">
            <div className={`column is-one-fifth is-3-mobile is-flex is-justify-content-right`}>
                <figure className="image">
                    <img className="" src={imageUrl} alt={imageAltText} loading={ props.index > LAZY_LOAD_THRESHOLD ? 'lazy' : 'eager' }/>
                </figure>
            </div>
            <div className="column is-9-mobile">
                {/* <div className="has-text-weight-semibold ml-2">{displayType}</div>
                <div className="has-text-weight-semibold has-text-grey-dark">998</div> */}
                <a href={itemLink} className="has-text-weight-semibold mr-2" href={itemLink} dangerouslySetInnerHTML={{ __html: label }}></a>
                <span className="section-search-list-meta mr-2">{mapping.dateLabel(props.result)}</span>
                <span className="section-search-list-meta mr-2" dangerouslySetInnerHTML={{ __html: makers }}></span>
                <span className="section-search-list-meta mr-2">{mapping.materials(props.result)}</span>
                <span className="section-search-list-meta mr-2">{mapping.dimension(props.result)}</span>
                { mapping.showDisplayType ? <span className="section-search-list-meta mr-2">{displayType}</span> : '' }
            </div>
        </div>
    </a>
</div>
}

const ResultsListView = (props) => {

    if (props.count === 0 || props.results == null || ( props.results.hits.total.value ?? props.results.hits.total ) < 1) {
      return <div className="px-4">No results were found matching "{props.searchStr}"</div>
    } else {

      return <div className="landing-page__table-container">
            <div>
                {
                  props.results.hits.hits.map( (hit, idx) => {
                      return <ResultListItem index={idx} key={idx} result={hit}/>
                  })
                }
            </div>
        </div>
      }
}

const ItemFigure = (props) => {

  const config = useContext(SearchConfigContext);
  const mapping = config.mapping;

  let minDensitySize = 'full'

  const imageUrl = mapping.imageUrl(props.source) ?? "";
  const imageSrcSet = mapping.imageSrcSet(props.source) ?? ""
  const displayType = mapping.displayType(props.source) ?? ""

  // FIXME: Populate the alt with a real value (eg, top-level classification)

  const errorHandler = (event) => {
    // If we hit an error, try dropping out of density-sensitive display and just try to show the image at our 1x density.
    // NB: This currently assumes an 2.1 endpoint

    // ... but do it under cover of night a bit
    event.target.classList.add('d-none')

    let currentImgUrl = new URL(event.target.currentSrc);
    var urlParams = currentImgUrl.pathname.split('/')
    let sizeParam = urlParams.slice(-3,-2).pop()
    let minSizeParam = minDensitySize // `,${minDensitySize}`

    switch (sizeParam) {
      case minSizeParam:
        // But if we're already at zero density, just set an error
        event.target.srcset = '/assets/images/image-404-242.png 1x' //`${currentImgUrl.href.replace(currentImgUrl.pathname,fullPath)} 1x`
        break;
      default:
        urlParams.splice(-3,1,minSizeParam)
        event.target.srcset = `${currentImgUrl.href.replace(currentImgUrl.pathname,urlParams.join('/'))} 1x`
    }

    event.target.onload = (event) => {
      event.target.classList.remove('d-none')
    }

  }

  // FIXME: handle alt tag here as well
  let fallbackImageSrcSet = getFallbackImageUrl(props.source,FALLBACK_IMAGE_SIZES.LARGE)
  let imageAltText = imageSrcSet === "" ? getImageAltText(props.source) : ""

  return (
      <img className={`thumb`}
          loading={ props.index > LAZY_LOAD_THRESHOLD ? 'lazy' : 'eager' }
          height={242}
          key={imageUrl}
          alt={imageAltText}
          srcSet={ (imageSrcSet === "") ? fallbackImageSrcSet : imageSrcSet }
          onError={sizeResettingErrorHandler()} />
  )
}

const ResultGridItem = (props) => {

  const config = useContext(SearchConfigContext);
  const labels = config.searchConfig.labels.reduce((acc,cur) => { acc[cur.name] = cur.label ; return acc }, {} )

  const mapping = config.mapping
  let itemLink = mapping.link(props.result)

  if ( props.result._id.search('^http(s?)://') > -1 ) {
    const ident = new URL(props.result._id);
    itemLink = `${ident.pathname}/`;
  }

  const displayType = mapping.displayType(props.result);
  const descriptionShort = (mapping.description(props.result) && truncateWordBoundary(mapping.description(props.result), 120)) ||
      'No description available';

  const label = DOMPurify.sanitize(mapping.label(props.result), {ALLOWED_TAGS: ['br']});
  const dateLabel = DOMPurify.sanitize(mapping.dateLabel(props.result), {ALLOWED_TAGS: ['br']});
  const makers = DOMPurify.sanitize(mapping.makers(props.result), {ALLOWED_TAGS: ['br']});

  // when we click for the quickview popup, we want to give the QV the image URL so it can preload it
  const imageUrl = mapping.imageUrl(props.result) ?? "";

  // PROBLEM: in some browsers, the onfocus and onclick events seem to happen in an indeterminate order.
  // esp. chrome on mac
  // the result is that sometimes, in those browsers, the onclick event doesn't trigger opening the QV.
  // SOLUTION: break down the 'click' event into mousedown + mouseup
  // in onmousedown, preventDefault so the focus doesn't happen
  // in onmouseup, do the focus stuff
  // NOTES: onclick would handle the Enter keypress, but onmouseup doesn't, so we need an extra handler for the Enter key

  // FIXME: This is the signature for the QV data (incredibly?)
  const itemData = {
      'result': props.result,
      'imageUrl': imageUrl,
      'listIdx': props.listIdx,   // or, idx in list
      'myList': props.myList,     // including the list allows the QV to operate a MultiCarousel of items
      'dataFormat': 'searchResults'
  }

  const goToItem = () => {
      window.location.href = itemLink;
  }

  const itemMouseDownHandler = (event) => {
      event.stopPropagation();
      event.preventDefault();
  }

  const itemMouseUpHandler = (event) => {
      event.preventDefault();
      if (event.button !== 0) {
          // ignore if not the main (usually left) mouse button
          return;
      }

      if ( event.target.classList.contains('grid-object-overlay-qv')
            || event.altKey
            || event.ctrlKey
            || event.shiftKey
            || event.metaKey ) {
          // we'll let the overlay click handler handle it
          console.log('no-op');
      } else if ( mapping.isReference !== undefined && mapping.isReference(props.result) ) {
        console.log('sup')
        itemQuickViewHandler(event)
        event.stopPropagation();
        // event.preventDefault();
      }
      else {
        console.log('goToItem')
          goToItem();
      }

      // make sure clicked item has focus - see https://zellwk.com/blog/inconsistent-button-behavior/
      // event.currentTarget.classList.toggle('focus-outline-none', true);   // disable focus outline, only when clicking, not tabbing
      // event.currentTarget.focus();
      // props.storeActive();
  }

  const itemFocusHandler = (event) => {
      if (props.focusfunc) props.focusfunc(event);
  }

  const itemKeyUpHandler = (event) => {
      if (event.key === 'Enter') {
          // console.log('enter key');
          goToItem();
          // props.storeActive();
      }
  }

  // open the quick view
  const itemQuickViewHandler = (event) => {
      if (event.button !== 0) {
          // ignore if not the main (usually left) mouse button
          return;
      }
      // console.log('itemQuickViewHandler');
      props.itemClickAction(event, itemData);
      // make sure clicked item has focus - see https://zellwk.com/blog/inconsistent-button-behavior/
      event.currentTarget.classList.toggle('focus-outline-none', true);   // disable focus outline, only when clicking, not tabbing
      event.currentTarget.focus();
      // props.storeActive();
      event.stopPropagation();
      event.preventDefault();
  }

  return <a href={itemLink} className="results-grid-item" tabIndex="0"  onClick={(e) => { if ( mapping.isReference !== undefined && mapping.isReference(props.result) ) {e.preventDefault(); e.stopPropagation()} } } onMouseUp={itemMouseUpHandler} onFocus={itemFocusHandler} onMouseDown={itemMouseDownHandler} onKeyUp={(e) => itemKeyUpHandler(e)}>
    <div className="section-archive-series-details-card">
      {/* inline-block display needed to meet the specs for wrapping the constrained text */}
      <div className="results-grid-item-inline-ctnr">
        <div className="results-grid-item-image">
          <div className="results-grid-item-visible-image">
            <ItemFigure index={props.listIdx} source={props.result} />
            <ItemOverlay clickFunction={itemQuickViewHandler} hideFullRecordLink={ ( mapping.isReference !== undefined && mapping.isReference(props.result) ) } />
          </div>
        </div>
        {/* Note: not sure why we need 'is-safari' in results grid on Safari, but we don't seem to need it in the relationships items */}
        <div className={`results-grid-width-constrained section-archive-series-details-card-text is-size-7 has-text-weight-semibold section-archive-series-details-card-text-creators has-text-grey-dark ${props.isSafari ? 'is-safari' : ''}`} dangerouslySetInnerHTML={{ __html: makers }}>
        </div>
        <div className={`results-grid-width-constrained section-archive-series-details-card-text is-size-6 has-text-weight-semibold ${props.isSafari ? 'is-safari' : ''}`} dangerouslySetInnerHTML={{ __html: label }}>
        </div>
        <div className={`results-grid-width-constrained section-archive-series-details-card-text is-size-7 has-text-grey ${props.isSafari ? 'is-safari' : ''} card-text-date`} dangerouslySetInnerHTML={{ __html: dateLabel }}>
        </div>
        {
          mapping.showDisplayType ? <div className={`results-grid-width-constrained section-archive-series-details-card-text is-size-7 has-text-grey ${props.isSafari ? 'is-safari' : ''}`}>
          { mapping.displayType(props.result) }
        </div> :
          ''
        }
      </div>
    </div>
  </a>
}

const ResultsGridView = (props) => {

    // needed along with QuickViewer:
    const [ qvData, setQvData ] = useState({});
    const resultsView = useRef(null);

    const openTheQV = (e, data) => {
        console.log('opening QV w. data:');
        console.log(data);
        setQvData(data);    // this will trigger the qv to open
        const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
        const body = document.body;
        body.style.height = '100vh';
        body.style.overflowY = 'hidden';
    }

    const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') == -1 &&
            navigator.userAgent.indexOf('FxiOS') == -1;

    let contentNode;

    if (props.count === 0) {
      contentNode = <div className="px-4">
          {`${props.labels.noResultsLine1} "${props.searchStr}".`}
          <br/>
          {props.labels.noResultsLine2}
        </div>
    } else if (props.count === null || props.results === null ) {
      contentNode = '';
    } else {
      contentNode = props.results.hits.hits.map((hit, idx) => {
        return (
            <ResultGridItem result={hit}
              itemClickAction={openTheQV}
              key={idx}
              myList={props.results.hits.hits} // !!!!!!!!!!!!!!! The entire result set is passed to every single grid item
              listIdx={idx}
              isSafari={isSafari}
            />
        );
      })
    }

    let topY = resultsView.current?.getBoundingClientRect()?.y || 0
    return (
      <div ref={resultsView} id="section-landing-items" className="gokm-search-results-grid mb-3">
        { contentNode }
        {/*
          FIXME: We can't naively pass data here--there can be 2k+ results!

          Maybe:
          - Just page along with the results frame, when QV hits the end of qvData see if there's more and update

        */}
        <QuickViewer
            myId='qvPanel-search-results'
            qvData={qvData}     // when this data is non-null it triggers the thing to open
            preloadClickedImg={true}
            minY={topY}
        />
        <div className="back-to-top-section">
          <button className="button button-icon" onClick={ () => window.scrollTo(0,0)} id="backtoTop">
          <span className="icon icon-arrow-up icon--black"></span><span className="button-text">Back To Top</span></button>
        </div>
      </div>
    );
}

const ResultsContextView = (props) => {
    return <div className="col-sm-3"><code>{props.aggregations}</code></div>
}

export { ResultsPageControl, ResultsView, RESULTS_MODE };